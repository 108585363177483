import React from "react"
import { graphql } from "gatsby";
import PageBuilder from '../components/pageBuilder'
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'

export const query = graphql`
	query PagePageQuery($id: String!) {
		sanityPage(id: { eq: $id }) {
			...PageBuilder
			pageName
			banner {
				asset {
					fluid {
					...GatsbySanityImageFluid
					}
				}
			}
		}
	}
	`

export default function PageTemplate({ location, data }) {
	const pageBuilder = data.sanityPage.pageBuilder

  return (
		<Layout location={location}>
			<SEO title={data.sanityPage.pageName} />
			<div key={data.sanityPage.id} className="site-page">
				<div className="container mx-auto">
					<div className="w-full h-80 md:h-banner bg-black">
					{
						(data.sanityPage.banner && data.sanityPage.banner.asset) &&
						<BackgroundImage
							className={'h-full relative'}
							fluid={data.sanityPage.banner.asset.fluid}
							backgroundColor={`#040e18`}
						>
							<div
								className="absolute w-60 bottom-10 left-1/2 transform -translate-x-1/2 lg:-translate-x-0 lg:right-0 lg:left-auto bottom-0 text-white flex flex-col justify-center items-center"
							>
								<div className="bg-black py-2 px-2 rounded-2xl">
									<h2 className="text-white text-2xl  sm:text-3xl md:text-4xl">{data.sanityPage.pageName}</h2>
								</div>
							</div>
						</BackgroundImage>
					}
					</div>
				</div>
				<div className="container mx-auto px-3 lg:px-0 mt-10">
					<PageBuilder pageBuilder={pageBuilder}/>
				</div>
			</div>
		</Layout>
  )
}
